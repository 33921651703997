<template>
  <div class="main">
    <Navigation />
    <div class="container">
      <div class="row mt-5">
        <div class="d-flex justify-content-center">
          <h1>USER MANAGEMENT</h1>
        </div>
        <div v-if="userView == 0">
          <div class="row mt-2 d-flex flex-row-reverse">
            <div class="col-md-5 d-flex flex-row-reverse">
              <button
                class="btn btn-success mb-2"
                v-on:click="CHANGE_VIEW(1, {})"
              >
                Add New
              </button>
            </div>
          </div>
          <hr />
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr class="text-center">
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  v-bind:key="data.id"
                  v-for="data in Users"
                >
                  <td>{{ data.firstName + " " + data.lastName }}</td>
                  <td>{{ data.email }}</td>
                  <td>{{ data.role }}</td>
                  <td>
                    <button
                      class="btn btn-primary"
                      v-on:click="CHANGE_VIEW(2, data)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                    </button>
                    <button
                      class="btn btn-danger ml-4"
                      v-on:click="DELETEUSER(data.id)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <div class="row mt-5">
            <div class="col-md-6">
              <label for="firstName">First Name:</label>
              <input class="form-control" type="text" v-model="firstName" />
            </div>
            <div class="col-md-6">
              <label for="firstName">Last Name:</label>
              <input class="form-control" type="text" v-model="lastName" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-6">
              <label for="birthday">Birthday:</label>
              <input class="form-control" type="date" v-model="birthday" />
            </div>
            <div class="col-md-6">
              <label for="email">Email Address:</label>
              <input class="form-control" type="email" v-model="email" />
            </div>
          </div>
          <div class="row mt-4 d-flex justify-content-center">
            <div class="col-md-6">
              <label for="username">Username:</label>
              <input class="form-control" type="text" v-model="username" />
            </div>
            <div class="col-md-6">
              <label for="username">Role:</label>
              <select class="form-control text-center" v-model="selectedRole">
                <option v-bind:key="data.id" v-for="data in Role">
                  {{ data.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mt-4" v-if="selectedRole =='Church'">
            <div class="col-md-6">
              <div class="form-group">
                <label>Operations:</label>
                <select
                  class="form-control"
                  v-model="selectedOperation"
                  v-on:change="searchReports"
                >
                  <option v-bind:key="data.id" v-for="data in Operation">
                    {{ data.Operation }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label" for="church">Region:</label>
                <select
                  id="optionProvince"
                  class="form-control"
                  v-model="selectedRegion"
                  v-on:change="searchReports"
                >
                  <option v-bind:key="data.id" v-for="data in Provincial">
                    {{ data.Province }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mt-4" v-if="selectedRole =='Church'">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label" for="church">District:</label>
                <select
                  id="optionDistrict"
                  class="form-control"
                  v-model="selectedDistrict"
                  v-on:change="searchReports"
                >
                  <option v-bind:key="data.id" v-for="data in Districts">
                    {{ data.district }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label" for="church">Churches:</label>
                <select
                  id="optionChurch"
                  class="form-control"
                  v-model="selectedChurch"
                  v-on:change="searchReport"
                >
                  <option v-bind:key="data.id" v-for="data in Churches">
                    {{ data.ChurchName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mt-5 d-flex flex-row-reverse">
            <div class="col-md-5 d-flex">
              <button
                class="btn btn-success mb-2 w-75"
                v-on:click="ADDUSER"
                v-if="userView == 1"
              >
                Insert
              </button>
              <button
                class="btn btn-primary mb-2 w-75"
                v-on:click="UPDATEUSER"
                v-if="userView == 2"
              >
                Update
              </button>
              <button
                class="btn btn-danger mb-2 w-75 ml-4"
                v-on:click="RESETALL"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Navigation from "../components/Navigation.vue";
export default {
  name: "Users",
  components: {
    Navigation,
  },
  data() {
    return {
      selectedOperation: "JIL Southern and Eastern Europe",
      selectedRegion: "North West",
      selectedDistrict: "Piemonte",
      selectedChurch:"",
      Operation: [
        {
          id: 0,
          Operation: "JIL Southern and Eastern Europe",
        },
      ],   
      Provincial: [
        {
          id: "NW",
          Province: "North West",
        },
        {
          id: "NE",
          Province: "North East",
        },
        {
          id: "C",
          Province: "Central",
        },
        {
          id: "S",
          Province: "South",
        },
        {
          id: "I",
          Province: "Islands",    
        },
      ],
      selectedUser: {},
      userView: 0,
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      birthday: "",
      Role: [
        {
          id: 0,
          value: "Admin",
        },
        {
          id: 1,
          value: "Country",
        },
        {
          id: 2,
          value: "Provincial",
        },
        {
          id: 3,
          value: "Church",
        },
      ],
      selectedRole: "",
      createdBy: "",
    };
  },
  created() {
    this.GETUSERS();
    this.GETCHURCH();
    var encoded = localStorage.getItem("log");
    var actual = JSON.parse(atob(encoded));
    this.$store.commit("SET_USERLOGON", actual);
    var encoded = localStorage.getItem("ch");
    var actual = JSON.parse(atob(encoded));
    this.$store.commit("SET_CHURCHES", actual);
    this.createdBy = this.LOGON_USER.FirstName + " " + this.LOGON_USER.LastName;
  },
  methods: {
    async GETCHURCH(){
       this.glob_Churches = [];
          await axios.get("https://api.jilcwseeo.org/api/Church/getChurches")
          .then((response)=>{
              let obj = response.data;
              if(obj.length > 0)
              {
                  for(let i=0;i<=obj.length-1;i++)
                  {
                          let obj_data= obj[i];
                          var church_obj = {
                              ChurchName : obj_data.churchName,
                              ChurchPastor:obj_data.churchPastor,
                              Provincial: obj_data.provincial,
                              Operation : obj_data.operation,
                              District: obj_data.district,
                              Id:obj_data.id,
                              ChurchId:obj_data.churchId
                          }
                           this.glob_Churches.push(church_obj);
                  }
                    this.$store.commit("SET_CHURCHES",this.glob_Churches);
                    var encoded = btoa(JSON.stringify(this.glob_Churches))
                    localStorage.setItem('ch',encoded);
              }
          })
          .catch((error)=>{
             Swal.fire({
                    title:"Can't connect to the server.",
                    icon:"error",
                    confirmButtonColor: '#0d6efd'
                    })
          });
    },
    CHANGE_VIEW: function (param_view, param_data) {
      if (param_view == 1) {
        this.userView = 1;
      } else if (param_view == 2) {
        var d = new Date(param_data.birthdate);
        var dateReport = new Date(d.getTime() - d.getTimezoneOffset() * 60000)
          .toISOString()
          .split("T")[0];
        this.userView = 2;
        this.selectedUser = param_data;
        this.firstName = param_data.firstName;
        this.lastName = param_data.lastName;
        this.selectedRole = param_data.role;
        this.email = param_data.email;
        this.username = param_data.username;
        this.birthday = dateReport;
        this.Churches.forEach((element)=>{
          if(element.Id == param_data.churchId)
          {
            this.selectedOperation = element.Operation;
            this.selectedRegion = element.Provincial;
            this.selectedDistrict = element.District;
            this.selectedChurch = element.ChurchName;
          }
        });
      }
    },
    ADDUSER: function () {
      if (
        this.firstName != "" &&
        this.lastName != "" &&
        this.birthday != "" &&
        this.email != "" &&
        this.username != "" &&
        this.selectedRole != "" &&
        this.selectedChurch !=""
      ) {
        swal
          .fire({
            text: "Do you want to continue?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            confirmButtonColor: "#0d6efd",
            cancelButtonColor: "#dc3545",
          })
          .then((result) => {
            if (result.isConfirmed) {
              var obj = {
                FirstName: this.firstName,
                LastName: this.lastName,
                Email: this.email,
                Username: this.username,
                Birthdate: this.birthday,
                createdBy: this.createdBy,
                Role: this.selectedRole,
                ChurchId:parseInt(this.FINDCHURCH().Id)
              };
              axios
                .post("https://api.jilcwseeo.org/api/User/insert-user", obj)
                .then((response) => {
                  if (response.status == 200) {
                    this.SWEETALERT(
                      "Success",
                      "Record has been added.",
                      "success"
                    );
                    this.RESETALL();
                    this.GETUSERS();
                  }
                })
                .catch((error) => {
                  console.log(error);
                  if (error.response) {
                    this.SWEETALERT("Error", "Something went wrong.", "error");
                  }
                });
            }
          });
      } else {
        Swal.fire({
          title: "Please fill up all textfield.",
          icon: "warning",
          confirmButtonColor: "#0d6efd",
        });
      }
    },
    FINDCHURCH:function(){
      var church;
      church = this.Churches.find(element => element.ChurchName == this.selectedChurch);
      return church;
    },
    RESETALL: function () {
      this.userView = 0;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.username = "";
      this.password = "";
      this.birthday = "";
    },
    GETUSERS: function () {
      axios.get("https://api.jilcwseeo.org/api/User/get-users").then((response) => {
        var arr= [];
        let obj = response.data;
        obj.forEach((element)=>{
          if(element.role == "Admin" || element.role == "Church" || element.role == "Country" ||  element.role== "Provincial")
          {
            arr.push(element);
          }
        });
        this.$store.commit("SET_USERS", arr);
      });
    },
    UPDATEUSER: function () {
      if (
        this.firstName != "" &&
        this.lastName != "" &&
        this.birthday != "" &&
        this.email != "" &&
        this.username != "" &&
        this.selectedRole != "" &&
        this.selectedChurch != ""
      ) {
        swal
          .fire({
            text: "Do you want to continue?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            confirmButtonColor: "#0d6efd",
            cancelButtonColor: "#dc3545",
          })
          .then((result) => {
            if (result.isConfirmed) {
              var e =  this.FINDCHURCH();
              var obj = {
                Id: this.selectedUser.id,
                FirstName: this.firstName,
                LastName: this.lastName,
                Email: this.email,
                Username: this.username,
                Birthdate: this.birthday,
                createdBy: this.createdBy,
                Role: this.selectedRole,
                ChurchId:parseInt(e.Id)     
              };
              axios
                .patch("https://api.jilcwseeo.org/api/User/update-user", obj)
                .then((response) => {
                  if (response.status == 200) {
                    this.RESETALL();
                    this.SWEETALERT(
                      "Success",
                      "Record has been updated.",
                      "success"
                    );           
                    this.GETUSERS();
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    this.SWEETALERT("Error", "Something went wrong.", "error");
                  }
                });
            }
          });
      } else {
        Swal.fire({
          title: "Please fill up all textfield.",
          icon: "warning",
          confirmButtonColor: "#0d6efd",
        });
      }
    },
    DELETEUSER: function (param) {
      swal
        .fire({
          title: "Are you sure you want to delete?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonColor: "#0d6efd",
          cancelButtonColor: "#dc3545",
        })
        .then((result) => {
          if (result.isConfirmed) {
            var obj = {
              Id: param,
            };
            axios
              .post("https://api.jilcwseeo.org/api/User/delete-user", obj)
              .then((response) => {
                if (response.status == 200) {
                  this.SWEETALERT(
                    "Success",
                    "Record has been deleted.",
                    "success"
                  );
                  this.GETUSERS();
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.SWEETALERT("Error", "Something went wrong.", "error");
                }
              });
          }
        });
    },
    SWEETALERT(param_title, param_text, param_action) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: param_action,
        title: param_title,
        text: param_text,
      });
    },
    searchReports() {
      var obj = {
        Operation: this.selectedOperation,
        Provincial: this.selectedRegion,
        District: this.selectedDistrict,
      };
      axios
        .post("https://api.jilcwseeo.org/api/Church/get-operation-Churches", obj)
        .then((response) => {
          let obj = response.data;
          if (obj.length > 0) {
            let glob_Churches = [];
            for (let i = 0; i <= obj.length - 1; i++) {
              let obj_data = obj[i];
              var church_obj = {
                ChurchName: obj_data.churchName,
                ChurchPastor: obj_data.churchPastor,
                Provincial: obj_data.provincial,
                Operation: obj_data.operation,
                District: obj_data.district,
                Id: obj_data.id,
                ChurchId: obj_data.churchId,
              };
              glob_Churches.push(church_obj);
            }
            this.$store.commit("SET_CHURCHES", glob_Churches);
            this.selectedChurch = glob_Churches[0].ChurchName;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$store.commit("SET_CHURCHES", []);
            this.selectedChurch = "";
          }
        });
    },
  },
  computed: {
    Users() {
      return this.$store.state.Users;
    },
    LOGON_USER() {
      return this.$store.state.Logon;
    },
    Churches() {
      return this.$store.state.Churches;
    },
    Districts:function(){
      let arr = [];
        if(this.selectedRegion == "North West"){      
          arr.push({id:"P1",district:'Piemonte'});
          arr.push({id:"VD",district:"Valle D'aosta"});
          arr.push({id:"Lig",district:'Liguria'});
          arr.push({id:"Lomba",district:'Lombardia'});    
        }
        else if(this.selectedRegion == "North East"){      
          arr.push({id:"TR",district:"Trentino"});
          arr.push({id:"VN",district:"Veneto"});
          arr.push({id:"FR",district:"Friuli"});
          arr.push({id:"ER",district:'Emilia Romagna'});    
        }
        else if(this.selectedRegion =="Central"){
           arr.push({id:"TO",district:"Toscana"});
           arr.push({id:"U",district:"Umbria"});
           arr.push({id:"MA",district:"Marche"});
           arr.push({id:"LA",district:"Lazio"});
        }
        else if(this.selectedRegion == "South")
        {
           arr.push({id:"AB",district:"Abruzzo"});
           arr.push({id:"MO",district:"Molise"});
           arr.push({id:"CA",district:"Campania"});
           arr.push({id:"PU",district:"Puglia"});
           arr.push({id:"BA",district:"Basilicata"});
           arr.push({id:"CA",district:"Calabria"});
        }
        else if(this.selectedRegion =="Islands"){
           arr.push({id:"SC",district:"Sicilia"});
           arr.push({id:"SA",district:"Sardegna"});       
        }
        this.selectedDistrict = arr[0].district;
        return arr;
    }
  },
};
</script>
<style scoped>
table {
  width: 100%;
}
thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
tbody {
  display: block;
  overflow-y: auto;
  table-layout: fixed;
  max-height: 400px;
}
@media screen and (max-width: 762px) {
    thead, tbody tr {
    font-size: 7px !important;
  }
}
</style>